
import { defineComponent, ref, reactive, onMounted, computed, h } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  ElButton,
  ElIcon,
  ElForm,
  ElFormItem,
  FormRules,
  FormInstance,
  ElNotification,
  ElInputNumber,
  ElUpload,
  UploadProps,
  UploadFile,
  ElAlert,
  ElMessageBox,
} from 'element-plus'
import { Back, UploadFilled, Delete } from '@element-plus/icons-vue'

import { routeNames } from '@/router'
import { IUpdateProductForm } from '@/types'
import { usePermissions, useProducts } from '@/core/compositions'
import { apiErrorNotification } from '@/core/helpers'
import { filesService, productsService } from '@/services'
import { localeDate } from '@/core/utils'
import { EPermissions } from '@/types'
import { acceptFileFormats, maxFileSizesInMb } from '@/config'

export default defineComponent({
  name: 'ProductView',

  components: {
    ElButton,
    ElIcon,
    Back,
    UploadFilled,
    ElForm,
    ElFormItem,
    ElInputNumber,
    ElUpload,
    ElAlert,
    Delete,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()

    const { groupedPermissionsWithLabels, hasPermission } = usePermissions()
    const { get } = useProducts()

    const loading = ref(true)

    const formRef = ref<FormInstance>()
    const formValues = reactive<IUpdateProductForm>({
      title: null,
      description: null,
      price: null,
      width: null,
      height: null,
      dpi: null,
      minPicturesCount: null,
      maxPicturesCount: null,
      imageRequiredInPoster: true,
      videoRequiredInPoster: true,
      thumbnailId: null,
    })
    const createdValue = ref<string | null>(null)
    const isArchivedValue = ref<boolean>(false)

    const elUploadRef = ref()
    const formUploadFile = ref<UploadFile | null>(null)
    const formUploadFileSrc = ref<string | null>(null)

    const formValidationRules = reactive<FormRules>({
      title: [
        {
          required: true,
          message: 'Title is required!',
          trigger: 'change',
        },
        {
          min: 1,
          max: 255,
          message: 'Title length should be 1 to 255',
          trigger: 'change',
        },
      ],
      description: [
        {
          max: 1024,
          message: 'Description max characters length is 1024',
          trigger: 'change',
        },
      ],
      price: [
        {
          required: true,
          message: 'Price is required!',
          trigger: 'change',
        },
      ],
      width: [
        {
          required: true,
          message: 'Width is required!',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 0) {
              callback()
            } else {
              callback(new Error('Width min value is 1'))
            }
          },
          message: 'Width min value is 1',
          trigger: 'change',
        },
      ],
      height: [
        {
          required: true,
          message: 'Height is required!',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 0) {
              callback()
            } else {
              callback(new Error('Height min value is 1'))
            }
          },
          message: 'Height min value is 1',
          trigger: 'change',
        },
      ],
      dpi: [
        {
          validator: (rule: any, value: any, callback: any) => {
            if (!value || (!isNaN(value) && value > 0)) {
              callback()
            } else {
              callback(new Error('DPI min value is 1'))
            }
          },
          message: 'DPI min value is 1',
          trigger: 'change',
        },
      ],
      minPicturesCount: [
        {
          required: true,
          message: 'Min pictures count is required!',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value <= (formValues?.maxPicturesCount || 0)) {
              callback()
            } else {
              callback(
                new Error(
                  'Min pictures count should be less value than Max pictures count!',
                ),
              )
            }
          },
          message:
            'Min pictures count should be less value than Max pictures count!',
          trigger: 'change',
        },
      ],
      maxPicturesCount: [
        {
          required: true,
          message: 'Max pictures count is required!',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value >= (formValues?.minPicturesCount || 0)) {
              callback()
            } else {
              callback(
                new Error(
                  'Max pictures count should be greater value than Min pictures count!',
                ),
              )
            }
          },
          message:
            'Max pictures count should be greater value than Min pictures count!',
          trigger: 'change',
        },
      ],
      imageRequiredInPoster: [
        {
          required: true,
          message: 'Is image required in poster is required!',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: boolean, callback: any) => {
            if (!value && !formValues.videoRequiredInPoster) {
              callback(
                new Error('Image or video should be required in product!'),
              )
            } else {
              callback()
            }
          },
          message: 'Image or video should be required in product!',
          trigger: 'change',
        },
      ],
      videoRequiredInPoster: [
        {
          required: true,
          message: 'Is video required in poster is required!',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: boolean, callback: any) => {
            if (!value && !formValues.imageRequiredInPoster) {
              callback(
                new Error('Image or video should be required in product!'),
              )
            } else {
              callback()
            }
          },
          message: 'Image or video should be required in product!',
          trigger: 'change',
        },
      ],
    })

    const productId = computed(() => {
      const productId = parseInt(route.params?.id as string)

      return !isNaN(productId) ? productId : null
    })

    const imageAcceptFormats = computed(() => {
      return acceptFileFormats.image.join(',')
    })

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      if (!productId.value) {
        ElNotification({
          message: 'Product not found!',
          type: 'error',
        })

        toProductsPage()
      }

      loading.value = true

      try {
        const product = await get(productId.value as number)

        createdValue.value = localeDate(product.createDateTime, 'Date')
        isArchivedValue.value = product.isArchived
        formUploadFileSrc.value = product.thumbnail?.url as string

        formValues.title = product.title
        formValues.description = product.description
        formValues.price = product.price
        formValues.width = product.width
        formValues.height = product.height
        formValues.dpi = product.dpi
        formValues.minPicturesCount = product.minPicturesCount
        formValues.maxPicturesCount = product.maxPicturesCount
        formValues.imageRequiredInPoster = product.imageRequiredInPoster
        formValues.videoRequiredInPoster = product.videoRequiredInPoster
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)

        toProductsPage()
      } finally {
        loading.value = false
      }
    }

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        if (formUploadFile.value) {
          const imageFormData = new FormData()
          imageFormData.append('image', formUploadFile.value.raw as Blob)

          const { id } = await filesService.uploadImage(
            imageFormData,
            'products-thumbnails',
          )
          formValues.thumbnailId = id
        }

        await productsService.update(productId.value as number, formValues)

        ElNotification({
          message: 'Product successfully update!',
          type: 'success',
        })

        getData()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const toProductsPage = () => {
      router.push({ name: routeNames.products })
    }

    const handleThumbnailFileChange: UploadProps['onChange'] = (uploadFile) => {
      formUploadFile.value = uploadFile

      const reader = new FileReader()
      reader.onload = (e: any) => {
        formUploadFileSrc.value = e.target.result
      }
      reader.readAsDataURL(uploadFile.raw as Blob)
    }

    const onRemoveUploadFile = () => {
      formUploadFile.value = null
      formUploadFileSrc.value = null
      elUploadRef.value.clearFiles()
    }

    const validateForm = () => {
      formRef.value?.validateField('minPicturesCount')
      formRef.value?.validateField('maxPicturesCount')
    }

    const onSubmitDelete = async () => {
      const confirm = await ElMessageBox.confirm('Warning', {
        message: h('span', null, [
          'Are you sure you want to archive ',
          h('span', { class: 'font-bold' }, `${formValues.title}?`),
        ]),
        confirmButtonText: 'Archive',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })

      if (!confirm) {
        return false
      }

      loading.value = true

      try {
        await productsService.delete(productId.value as number)

        ElNotification({
          message: 'Product successfully archived!',
          type: 'success',
        })

        toProductsPage()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      formValues,
      formValidationRules,
      formRef,
      groupedPermissionsWithLabels,
      onSubmit,
      toProductsPage,
      handleThumbnailFileChange,
      formUploadFile,
      formUploadFileSrc,
      Delete,
      elUploadRef,
      onRemoveUploadFile,
      validateForm,
      createdValue,
      isArchivedValue,
      hasPermission,
      EPermissions,
      onSubmitDelete,
      imageAcceptFormats,
      maxFileSizesInMb,
      productId,
    }
  },
})
